import React, { useEffect } from "react"
import { Button, Col, Form, Input, message, Modal, Row, Table } from "antd"

import LocalizedAniLink from "../../components/localizedAniLink"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { navigate, useIntl } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import { callbackRolesErrorMessages } from "../../services/roles/rolesRequestErrorMessages"
import useStateObject from "../../hooks/useStateObject"
import deleteIcon from "../../assets/images/ingests/ic-delete-on.png"

const { confirm } = Modal
const { Search } = Input

const RoleList = () => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    roles: [],
    rolesFiltered: [],
    searchText: "",
    ui: {
      loadingTable: true,
    },
  })
  useEffect(() => {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/roles`)
      .then(response => {
        callbackRoles(response)
      })
      .catch(error => {
        callbackRolesErrorMessages(null, "LIST").then(text => {
          message.error(intl.formatMessage({ id: text }))
        })
        loadingTable(false)
        throw new Error(`[ERROR] Retrieving role list: ${error}`)
      })
  }, [])

  const callbackRoles = response => {
    if (response.status !== 200) {
      callbackRolesErrorMessages(response, "LIST").then(text => {
        message.error(intl.formatMessage({ id: text }))
      })
    } else {
      response.json().then(data => {
        setState({
          roles: data,
          rolesFiltered: data,
        })
      })
    }
    loadingTable(false)
  }

  const handleFilterRoles = e => {
    const searchText = e.currentTarget.value
    const { roles } = state
    const rolesFiltered = filterRolesBySearchText(roles, searchText)
    setState({
      rolesFiltered,
      searchText,
    })
  }

  const filterRolesBySearchText = (roles, searchText) => {
    return roles.filter(item =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  const onRowDelete = role => {
    confirm({
      title: intl.formatMessage({ id: "modal-are-you-sure" }),
      content: intl.formatMessage(
        { id: "page-role-delete-role-modal-text" },
        { name: role.name }
      ),
      okText: intl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "modal-ko" }),
      onOk() {
        authFetch(`${process.env.GATSBY_CONF_API_URL}/roles/${role.name}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            callbackRemoveRole(response, role)
          })
          .catch(error => {
            callbackRemoveRoleError(error)
          })
      },
      onCancel() {
        message.info(intl.formatMessage({ id: "modal-delete-cancel" }))
      },
    })
  }

  const callbackRemoveRole = (response, role) => {
    if (response.status === 204) {
      const newRoles = state.roles.filter(u => role.name !== u.name)
      const newRolesFiltered = filterRolesBySearchText(
        newRoles,
        state.searchText
      )
      setState({
        roles: newRoles,
        rolesFiltered: newRolesFiltered,
      })
      message.success(intl.formatMessage({ id: "page-role-delete-ok-text" }))
    } else {
      callbackRolesErrorMessages(response, "DELETE").then(text => {
        message.error(intl.formatMessage({ id: text }))
      })
    }
  }

  const callbackRemoveRoleError = () => {
    callbackRolesErrorMessages(null, "DELETE").then(text => {
      message.error(intl.formatMessage({ id: text }))
    })
  }

  const loadingTable = value => {
    const { ui } = state
    ui.loadingTable = value
    setState({ ui })
  }

  const columnsTable = [
    {
      title: intl.formatMessage({ id: "name" }),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: "page-role-ningest-table-field",
      }),
      dataIndex: "ingests",
      key: "ingests",
      align: "center",
      render: ingests => (ingests ? ingests.length : "-"),
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      width: 120,
      align: "center",
      render: (text, role) => {
        return (
          <button
            className="button-no-styles ingest-actions__button"
            onClick={event => {
              event.stopPropagation()
              onRowDelete(role)
            }}
          >
            <img
              style={{ fontSize: "1.25rem" }}
              className="ingest-actions__button__delete"
              src={deleteIcon}
              alt="deleteIcon"
            />
          </button>
        )
      },
      ellipsis: true,
    },
  ]

  return (
    <Template selected={["role", "role-list"]}>
      <CustomBreadcrumb
        crumbs={[intl.formatMessage({ id: "menu-role-managment" })]}
      />
      <Row>
        <Col className="knolar-intro">
          {intl.formatMessage({ id: "menu-role-list" })}
        </Col>
      </Row>
      <div className="content">
        <Row type="flex" justify="center" align="top">
          <Col span={12} align="left">
            <Form.Item>
              <Search
                style={{ height: "40px" }}
                placeholder={intl.formatMessage({
                  id: "page-role-filter-role-placeholder",
                })}
                onInput={handleFilterRoles}
                disabled={state.ui.loadingTable}
              />
            </Form.Item>
          </Col>
          <Col span={12} align="right">
            {checkPermissions(["role:write"]) ? (
              <LocalizedAniLink to="/role/new-role">
                <Button type="primary" className="knolar-button">
                  {intl.formatMessage({ id: "menu-role-new" })}
                </Button>
              </LocalizedAniLink>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="ingests-table"
              showHeader={true}
              size="small"
              rowKey={item => item.name}
              columns={columnsTable}
              dataSource={state.rolesFiltered}
              loading={state.ui.loadingTable}
              pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              onRow={r => ({
                onClick: () => navigate(`/role/${r.name}`),
              })}
              locale={{
                filterTitle: intl.formatMessage({
                  id: "filters",
                }),
                filterConfirm: intl.formatMessage({
                  id: "apply",
                }),
                filterReset: intl.formatMessage({ id: "reset" }),
                emptyText: intl.formatMessage({ id: "no-data" }),
              }}
            />
          </Col>
        </Row>
      </div>
    </Template>
  )
}

export default withAuth(RoleList)
